<template>
  <b-container fluid>
    <iq-card>
        <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('sidebar.warehouseSubNav.marketInfo') }}</h4>
        </template>
        <template v-slot:body>
            <b-row>
                <b-col xs="12" sm="12" md="6" lg="6">
                    <b-form-group
                    class="row"
                    label-cols-sm="3"
                    :label="$t('warehouse_config.region')"
                    label-for="division_id"
                    >
                    <b-form-select
                        plain
                        v-model="search.region_id"
                        :options="regionLists"
                        id="division_id"
                        >
                        <template v-slot:first>
                        <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                    </b-form-select>
                    </b-form-group>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6">
                    <b-form-group
                    class="row"
                    label-cols-sm="3"
                    :label="$t('org_pro_district.district')"
                    label-for="district_id"
                    >
                    <b-form-select
                        plain
                        v-model="search.district_id"
                        :options="regionDistrictLists"
                        id="district_id"
                        >
                        <template v-slot:first>
                        <b-form-select-option :value="0" >{{ districtLoading ? $t('globalTrans.loading') : $t('globalTrans.select') }}</b-form-select-option>
                        </template>
                    </b-form-select>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col xs="12" sm="12" md="6" lg="6">
                    <b-form-group
                    class="row"
                    label-cols-sm="3"
                    :label="$t('org_pro_upazilla.upazilla')"
                    label-for="upazilla_id"
                    >
                    <b-form-select
                        plain
                        v-model="search.upazilla_id"
                        :options="upazilaList"
                        id="upazilla_id"
                        >
                        <template v-slot:first>
                        <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                    </b-form-select>
                    </b-form-group>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6">
                    <b-form-group
                        class="row"
                        label-cols-sm="3"
                        :label="$t('warehouse_config.warehouse')"
                        label-for="union_id"
                        >
                        <b-form-select
                        plain
                        v-model="search.warehouse_id"
                        :options="warehouseLists"
                        id="union_id"
                        >
                        <template v-slot:first>
                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                        </b-form-select>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col xs="12" sm="12" md="6" lg="6">
                    <b-form-group
                    class="row"
                    label-cols-sm="3"
                    :label="$t('warehouse_config.marketName')"
                    label-for="office_code"
                    >
                    <b-form-input
                        id="office_code"
                        v-model="search.market_name"
                        placeholder=""
                        ></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6">
                    <b-button type="button" variant="primary float-right" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
                </b-col>
            </b-row>
        </template>
    </iq-card>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('sidebar.warehouseSubNav.marketInfo') }}</h4>
          </template>
          <template v-slot:headerAction>
            <b-button class="btn_add_new" v-b-modal.modal-4 @click="resetId">
              <i class="fas fa-plus"></i>{{  $t('globalTrans.add_new') }}
            </b-button>
          </template>
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <b-row>
                <b-col md="12" class="table-responsive">
                  <b-table thead-class="table_head" bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + pagination.slOffset) }}
                    </template>
                    <template v-slot:cell(market_name)="data">
                      <span class="capitalize">{{ data.item.market_name }}</span>
                    </template>
                    <template v-slot:cell(region_name)="data">
                      <span class="capitalize">{{ data.item.region_name }}</span>
                    </template>
                    <template v-slot:cell(warehouse_name)="data">
                      <span class="capitalize">{{ data.item.warehouse_name }}</span>
                    </template>
                    <template v-slot:cell(status)="data">
                      <!-- {{ data.item.status ? $t('globalTrans.inactive') : $t('globalTrans.active') }} -->
                      <b-badge variant="danger" v-if="data.item.status == 1">{{$t('globalTrans.inactive')}}</b-badge>
                      <b-badge variant="success" v-else>{{$t('globalTrans.active')}}</b-badge>
                    </template>
                    <template v-slot:cell(action)="data">
                      <!-- <b-button v-b-modal.modal-4 variant=" iq-bg-success mr-1 mb-1" size="sm" @click="edit(data.item)"><i class="ri-ball-pen-fill m-0"></i></b-button> -->
                      <!-- <b-button variant=" iq-bg-danger" size="sm" @click="remove(data.item)"><i class="ri-delete-bin-line m-0"></i></b-button> -->
                      <a href="javascript:" class="btn_table_action table_action_edit" title="Edit" v-b-modal.modal-4 size="sm" @click="edit(data.item)">
                          <i class="ri-ball-pen-fill"></i>
                      </a>
                      <a href="javascript:" class="btn_table_action table_action_toggle" title="Status" size="sm" @click="remove(data.item)" v-if="data.item.status === 1">
                          <i class="fas fa-toggle-off"></i>
                      </a>
                      <a href="javascript:" class="btn_table_action table_action_status" title="Status" size="sm" @click="remove(data.item)" v-else>
                          <i class="fa fa-toggle-on"></i>
                      </a>
                    </template>
                  </b-table>
                  <b-pagination
                  align="center"
                  v-if="pagination.totalRows"
                  v-model="pagination.currentPage"
                  :per-page="pagination.perPage"
                  :total-rows="pagination.totalRows"
                  @input="searchData"
                  />
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-modal id="modal-4" size="lg" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <p>
        <FormV :id="editItemId" :key="editItemId" />
      </p>
    </b-modal>
    <b-modal id="modal-6" size="lg" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <p>
        <FormU :id="editItemId" :key="editItemId"/>
      </p>
    </b-modal>

  </b-container>
</template>
<script>
import FormV from './Form'
import FormU from './Form1'
import RestApi, { warehouseServiceBaseUrl } from '@/config/api_config'
import { marketInfoList, marketInfoToggleStatus } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    FormV, FormU
  },
  data () {
    return {
      search: {
        region_id: '0',
        warehouse_id: '0',
        district_id: '0',
        upazilla_id: '0',
        market_name: ''
      },
      regionDistrictLists: [],
      upazilaList: [],
      unionList: [],
      warehouseLists: [],
      districtLoading: false
    }
  },
  computed: {
    authUser () {
      return this.$store.state.Auth.authUser
    },
    isWareHouseUser () {
      return this.authUser.warehouse_id
    },
    formTitle () {
       return (this.editItemId === 0) ? this.$t('sidebar.warehouseSubNav.marketInfo') + ' ' + this.$t('globalTrans.entry') : this.$t('sidebar.warehouseSubNav.marketInfo') + ' ' + this.$t('globalTrans.modify')
    },
    columns () {
      const labels = [
        { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
        { label: this.$t('warehouse_config.marketName'), class: 'text-center' },
        { label: this.$t('warehouse_config.region'), class: 'text-center' },
        { label: this.$t('warehouse_config.warehouse'), class: 'text-center' },
        { label: this.$t('globalTrans.status'), class: 'text-center' },
        { label: this.$t('globalTrans.action'), class: 'text-center' }
      ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'market_name_bn' },
          { key: 'region_name_bn' },
          { key: 'warehouse_name_bn' },
          { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'market_name' },
          { key: 'region_name' },
          { key: 'warehouse_name' },
          { key: 'status' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
        return Object.assign(item, keys[index])
      })
    },
    divisionList: function () {
      return this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
    },
    regionDetailList: function () {
      return this.$store.state.warehouse.regionDetailList
    },
    regionLists: function () {
        return this.$store.state.warehouse.regionList.filter(item => item.status === 0)
    }
  },
  watch: {
    'search.region_id': function (newVal, oldVal) {
      this.getRegionDistrictList(newVal)
    },
    'search.district_id': function (newVal, oldVal) {
      this.upazilaList = this.getUpazilaList(newVal)
    },
    'search.upazilla_id': function (newVal, oldVal) {
      this.warehouseLists = this.getWarehouse(newVal)
    }
  },
  created () {
  },
  async mounted () {
    if (this.isWareHouseUser) {
      this.search.division_id = this.authUser.office_detail.division_id
      this.search.region_id = this.regionDetailList.find(item => item.district_id === this.authUser.office_detail.district_id).region_id
      this.search.district_id = this.authUser.office_detail.district_id
      this.search.upazilla_id = this.authUser.office_detail.upazilla_id ?? 0
      this.search.union_id = this.authUser.office_detail.union_id ?? 0
      const warehouse = this.$store.state.warehouse.warehouseInfoList.find(doc => doc.office_id === this.authUser.office_detail.id)
      this.search.warehouse_id = warehouse.value
    }
    this.loadData()
  },
  methods: {
    searchData () {
      this.loadData()
    },
    remove (item) {
      this.changeStatus(warehouseServiceBaseUrl, marketInfoToggleStatus, item, 'warehosue', 'marketInfoList')
    },
    loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.getData(warehouseServiceBaseUrl, marketInfoList, params).then(response => {
          if (response.success) {
            this.$store.dispatch('setList', response.data.data)
            this.paginationData(response.data)
          }
          this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    },
    async getRegionDistrictList (regionId = null) {
      this.districtLoading = true
      await RestApi.getData(warehouseServiceBaseUrl, '/master-warehouse-level/region-district-list').then(response => {
        if (response) {
          this.regionDistrictLists = []
          const dataObject = response.filter(document => document.region_id === regionId)
          dataObject.map(obj => {
            const district = this.$store.state.commonObj.districtList.find(document => document.value === obj.district_id)
            if (typeof district !== 'undefined') {
              const item = {
                value: obj.district_id,
                text: this.$i18n.locale === 'bn' ? district.text_bn : district.text_en
              }
              this.regionDistrictLists.push(item)
            }
          })
        }
      })
      this.districtLoading = false
    },
    getWarehouse (upazilaId = null) {
      const warehouseList = this.$store.state.warehouse.warehouseInfoList.filter(item => item.status === 0)
      if (upazilaId) {
        return warehouseList.filter(document => document.upazilla_id === upazilaId)
      }
      return warehouseList
    },
    getUpazilaList (districtId = null) {
      const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)

      if (districtId) {
        return upazilaList.filter(upazila => upazila.district_id === districtId)
      }

      return upazilaList
    }
  }
}
</script>
