<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-row>
              <b-col lg="12" sm="12">
                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                <b-form  @submit.prevent="handleSubmit(register)" @reset.prevent="reset" >
                <ValidationProvider name="Division" vid='division_id' rules="required|min_value:1">
                  <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="division"
                    slot-scope="{ valid, errors }"
                    >
                    <template v-slot:label>
                      {{ $t('org_pro_division.division')}} <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                      plain
                      v-model="formData.division_id"
                      :options="divisionList"
                      id="division_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                      :disabled="isWareHouseUser"
                      >
                      <template v-slot:first>
                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
                <ValidationProvider name="Region" vid='region_id' rules="required|min_value:1">
                  <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="division"
                    slot-scope="{ valid, errors }"
                    >
                    <template v-slot:label>
                      {{ $t('warehouse_config.region')}} <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                      plain
                      v-model="formData.region_id"
                      :options="regionLists"
                      id="region_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                      :disabled="isWareHouseUser"
                      >
                      <template v-slot:first>
                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
                <ValidationProvider name="District" vid='district_id' rules="required|min_value:1">
                  <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="district"
                    slot-scope="{ valid, errors }"
                    >
                     <template v-slot:label>
                      {{ $t('org_pro_district.district')}} <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                      plain
                      v-model="formData.district_id"
                      :options="regionDistrictLists"
                      id="district_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                      :disabled="isWareHouseUser"
                      >
                      <template v-slot:first>
                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
                <ValidationProvider name="Upazila" vid='upazila_id' rules="required|min_value:1">
                  <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="upazila_id"
                    slot-scope="{ valid, errors }"
                    >
                     <template v-slot:label>
                      {{ $t('org_pro_upazilla.upazilla')}} <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                      plain
                      v-model="formData.upazilla_id"
                      :options="upazilaList"
                      id="upazila_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                      :disabled="isWareHouseUser"
                      >
                      <template v-slot:first>
                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
                <ValidationProvider name="Union" vid='union_id' rules="">
                  <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="union_id"
                      slot-scope="{ valid, errors }"
                  >
                    <template v-slot:label>
                      {{ $t('org_pro_union.union')}} <span class="text-danger"></span>
                    </template>
                    <b-form-select
                        plain
                        v-model="formData.union_id"
                        :options="unionList"
                        id="union_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                        :disabled="isWareHouseUser"
                    >
                      <template v-slot:first>
                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
                <ValidationProvider name="Warehouse Type" vid='warehouse_type_id' rules="required|min_value:1">
                  <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="warehouse_type_id"
                      slot-scope="{ valid, errors }"
                  >
                    <template v-slot:label>
                      {{ $t('warehouse_config.warehouse_type')}} <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                        plain
                        v-model="formData.warehouse_type_id"
                        :options="warehouseTypeList"
                        id="warehouse_type_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                        :disabled="isWareHouseUser"
                    >
                      <template v-slot:first>
                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
                <ValidationProvider name="Warehouse Category" vid='warehouse_category_id' rules="required|min_value:1">
                  <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="warehouse_category_id"
                      slot-scope="{ valid, errors }"
                  >
                    <template v-slot:label>
                      {{ $t('warehouse_config.warehouse_category')}} <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                        plain
                        v-model="formData.warehouse_category_id"
                        :options="warehouseCategoryList"
                        id="warehouse_category_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                        :disabled="isWareHouseUser"
                    >
                      <template v-slot:first>
                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
                <ValidationProvider name="Warehouse" vid='warehouse_id' rules="required|min_value:1">
                  <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="warehouse"
                    slot-scope="{ valid, errors }"
                    >
                    <template v-slot:label>
                      {{ $t('warehouse_config.warehouse')}} <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                      plain
                      v-model="formData.warehouse_id"
                      :options="warehouseList"
                      id="warehouse_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                      :disabled="isWareHouseUser"
                      >
                      <template v-slot:first>
                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                    <div class="text-success" v-if="warehouseActualCapacity">
                      Warehouse actual space {{ warehouseActualCapacity }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
                <div v-if="!id">
                  <b-row v-for="(setLevel, index) in formData.market_data" :key="index">
                    <b-col lg="11" sm="11" class="pr-lg-0 pr-sm-0">
                      <b-row>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Market Name (En)" vid='market_name' rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="5"
                                label-for="market_name"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{ $t('warehouse_config.marketNameEn')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                                  id="market_name"
                                  v-model="setLevel.market_name"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Market Name (Bn)" vid='market_name_bn' rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="5"
                                label-for="market_name_bn"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{ $t('warehouse_config.marketNameBn')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                                  id="market_name_bn"
                                  v-model="setLevel.market_name_bn"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col lg="6" sm="6">
                          <b-form-group
                              class="row"
                              label-cols-sm="5"
                              label-for="address"
                          >
                            <template v-slot:label>
                              {{ $t('warehouse_config.addressEn')}}
                            </template>
                            <b-form-textarea
                                id="address"
                                v-model="setLevel.address"
                            ></b-form-textarea>
                          </b-form-group>
                        </b-col>
                        <b-col lg="6" sm="6">
                          <b-form-group
                              class="row"
                              label-cols-sm="5"
                              label-for="address_bn"
                          >
                            <template v-slot:label>
                              {{ $t('warehouse_config.addressBn')}}
                            </template>
                            <b-form-textarea
                                id="address_bn"
                                v-model="setLevel.address_bn"
                            ></b-form-textarea>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </b-col>
                    <b-col lg="1" sm="1">
                        <b-button v-show="index == formData.market_data.length-1" variant=" iq-bg-success" size="sm" @click="add()"><i class="ri-add-line m-0"></i></b-button>
                        <b-button v-show="index || ( !index && formData.market_data.length > 1)" variant=" iq-bg-danger" size="sm" @click="remove(index)"><i class="ri-delete-bin-line m-0"></i></b-button>
                    </b-col>
                  </b-row>
                </div>
                <div v-else>
                  <b-row>
                    <b-col lg="6" sm="6">
                      <ValidationProvider name="Market Name (En)" vid='market_name' rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="5"
                            label-for="market_name"
                            slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{ $t('warehouse_config.marketNameEn')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                              id="market_name"
                              v-model="formData.market_name"
                              :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="6" sm="6">
                      <ValidationProvider name="Market Name (Bn)" vid='market_name_bn' rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="5"
                            label-for="market_name_bn"
                            slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{ $t('warehouse_config.marketNameBn')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                              id="market_name_bn"
                              v-model="formData.market_name_bn"
                              :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col lg="6" sm="6">
                      <b-form-group
                          class="row"
                          label-cols-sm="5"
                          label-for="address"
                      >
                        <template v-slot:label>
                          {{ $t('warehouse_config.addressEn')}}
                        </template>
                        <b-form-textarea
                            id="address"
                            v-model="formData.address"
                        ></b-form-textarea>
                      </b-form-group>
                    </b-col>
                    <b-col lg="6" sm="6">
                      <b-form-group
                          class="row"
                          label-cols-sm="5"
                          label-for="address_bn"
                      >
                        <template v-slot:label>
                          {{ $t('warehouse_config.addressBn')}}
                        </template>
                        <b-form-textarea
                            id="address_bn"
                            v-model="formData.address_bn"
                        ></b-form-textarea>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </div>
                <div class="row">
                  <div class="col-sm-3"></div>
                  <div class="col text-right">
                    <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                    &nbsp;
                    <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                  </div>
                </div>
              </b-form>
            </ValidationObserver>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { warehouseServiceBaseUrl } from '../../../../../config/api_config'
import { marketInfoStore, marketInfoUpdate } from '../../api/routes'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.id) {
      const tmp = this.getMarketInfoData()
      this.formData = tmp
    }
  },
    mounted () {
    core.index()
    if (this.isWareHouseUser) {
      this.formData.division_id = this.authUser.office_detail.division_id
      this.formData.region_id = this.regionDetailList.find(item => item.district_id === this.authUser.office_detail.district_id).region_id
      this.formData.district_id = this.authUser.office_detail.district_id
      this.formData.upazilla_id = this.authUser.office_detail.upazilla_id ?? 0
      this.formData.union_id = this.authUser.office_detail.union_id ?? 0
      const warehouse = this.$store.state.warehouse.warehouseInfoList.find(doc => doc.office_id === this.authUser.office_detail.id)
      this.formData.warehouse_id = warehouse.value
      this.formData.warehouse_type_id = warehouse.warehouse_type_id
      this.formData.warehouse_category_id = warehouse.warehouse_category_id
    }
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      formData: {
        division_id: '0',
        region_id: '0',
        district_id: '0',
        upazilla_id: '0',
        union_id: '0',
        warehouse_type_id: '0',
        warehouse_category_id: '0',
        warehouse_id: '0',
        market_name: '',
        market_name_bn: '',
        address: '',
        address_bn: '',
        market_data: [
          {
            market_name: '',
            market_name_bn: '',
            address: '',
            address_bn: ''
          }
        ],
        status: 0,
        created_by: 1,
        updated_by: 1
      },
      regionDistrictLists: [],
      upazilaList: [],
      unionList: [],
    //   warehouseTypeList: [],
      warehouseCategoryList: [],
      warehouseList: [],
      warehouseActualCapacity: 0
    }
  },
  computed: {
    authUser () {
      return this.$store.state.Auth.authUser
    },
    isWareHouseUser () {
      return this.authUser.warehouse_id
    },
    divisionList: function () {
      return this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
    },
    regionLists: function () {
        return this.$store.state.warehouse.regionList.filter(item => item.status === 0)
    },
    warehouseTypeList: function () {
        return this.$store.state.warehouse.warehouseTypeList.filter(item => item.status === 0)
    },
    // warehouseCategoryList: function () {
    //     return this.$store.state.warehouse.warehouseCategoryList.filter(item => item.status === 0)
    // },
    regionDetailList: function () {
      return this.$store.state.warehouse.regionDetailList
    }
  },
  watch: {
    'formData.region_id': function (newVal, oldVal) {
      this.getRegionDistrictList(newVal)
    },
    'formData.district_id': function (newVal, oldVal) {
      this.upazilaList = this.getUpazilaList(newVal)
    },
    'formData.upazilla_id': function (newVal, oldVal) {
      this.warehouseList = this.getWarehouse(newVal, 'upz')
      this.unionList = this.getUnionList(newVal)
    },
    'formData.union_id': function (newVal, oldVal) {
      this.warehouseList = this.getWarehouse(newVal, 'up')
    },
    'formData.warehouse_type_id': function (newVal, oldVal) {
      this.warehouseCategoryList = this.getWarehouseCategoryList(newVal)
    },
    'formData.warehouse_category_id': function (newVal, oldVal) {
      this.warehouseList = this.getWarehouse(newVal, 'ct')
    }
  },
  methods: {
    add () {
      const stepName = {
        market_name: '',
        market_name_bn: '',
        address: '',
        address_bn: ''
      }
      this.formData.market_data.push(stepName)
    },
    remove (key) {
      this.formData.market_data.splice(key, 1)
    },
    getMarketInfoData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    async register () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadingState = { loading: false, listReload: false }
      if (this.formData.id) {
        result = await RestApi.putData(warehouseServiceBaseUrl, `${marketInfoUpdate}/${this.id}`, this.formData)
      } else {
        result = await RestApi.postData(warehouseServiceBaseUrl, marketInfoStore, this.formData)
      }

      loadingState.listReload = true

      this.$store.dispatch('mutateCommonProperties', loadingState)

      if (result.success) {
        this.$store.dispatch('mutateWarehouseProperties', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-4')
      } else {
        if (result.errors) {
          this.$refs.form.setErrors(result.errors)
          this.$toast.error({
            title: 'Error',
            message: 'Operation failed! Please, try again.'
          })
        } else {
          this.$toast.error({
            title: 'Error',
            message: result.message
          })
        }
      }
    },
    getRegionDistrictList (regionId = null) {
      RestApi.getData(warehouseServiceBaseUrl, '/master-warehouse-level/region-district-list').then(response => {
        if (response) {
          this.regionDistrictLists = []
          const dataObject = response.filter(document => document.region_id === regionId)
          dataObject.map(obj => {
            const district = this.$store.state.commonObj.districtList.find(document => document.value === obj.district_id)
            if (typeof district !== 'undefined') {
              const item = {
                value: obj.district_id,
                text: this.$i18n.locale === 'bn' ? district.text_bn : district.text_en
              }
              this.regionDistrictLists.push(item)
            }
          })
        }
      })
    },
    getUpazilaList (districtId = null) {
      const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)
      if (districtId) {
        return upazilaList.filter(upazila => upazila.district_id === districtId)
      }
      return upazilaList
    },
    getUnionList (upazilaId = null) {
      const unionList = this.$store.state.commonObj.unionList.filter(item => item.status === 0)
      if (upazilaId) {
        return unionList.filter(union => union.upazilla_id === upazilaId)
      }
      return unionList
    },
    getWarehouseTypeList (unionId = null) {
      const warehouseInfoList = [...new Set(this.$store.state.warehouse.warehouseInfoList.filter(item => item.status === 0 && item.union_id === unionId))]
      const warehouseTypeIdArr = []

      if (warehouseInfoList.length) {
        warehouseInfoList.forEach(item => {
          warehouseTypeIdArr.push(item.warehouse_type_id)
        })
      }
      const warehouseTypeList = this.$store.state.warehouse.warehouseTypeList.filter(item => item.status === 0 && warehouseTypeIdArr.includes(item.value))

      return warehouseTypeList
    },
    getWarehouseCategoryList (warehouseTypeId = null) {
      const warehouseInfoList = [...new Set(this.$store.state.warehouse.warehouseInfoList.filter(item => item.status === 0 && item.warehouse_type_id === warehouseTypeId))]
      const warehouseCategoryIdArr = []

      if (warehouseInfoList.length) {
        warehouseInfoList.forEach(item => {
          warehouseCategoryIdArr.push(item.warehouse_category_id)
        })
      }
      const warehouseCategoryList = this.$store.state.warehouse.warehouseCategoryList.filter(item => item.status === 0 && warehouseCategoryIdArr.includes(item.value))

      return warehouseCategoryList
    },
    getWarehouse (warehouseCategoryId = null, type) {
      const warehouseList = this.$store.state.warehouse.warehouseInfoList.filter(item => item.status === 0)
      if (warehouseCategoryId && type === 'upz') {
        return warehouseList.filter(warehouse => warehouse.upazilla_id === warehouseCategoryId)
      }

      if (warehouseCategoryId && type === 'up') {
        return warehouseList.filter(warehouse => warehouse.union_id === warehouseCategoryId)
      }

      if (warehouseCategoryId && type === 'ct') {
        return warehouseList.filter(warehouse => warehouse.warehouse_category_id === warehouseCategoryId)
      }

      return warehouseList
    }
  }
}
</script>
